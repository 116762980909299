import { usePagination } from 'react-instantsearch'
import { styled } from '@/src/stitches.config'
import PaginationButton from '@/src/modules/algolia/PaginationButton'

const StyledPagination = styled('div', {
    pt: 30,
    width: 'fit-content',
    margin: 'auto',
    display: 'flex',
    jc: 'center',
    flexFlow: 'wrap',
    gap: 10,
    '.selected': {
        background: '$primaryLight',
    },
    '.disable': {
        background: '$white',
        borderColor: '$mediumGray !important',
        color: '$mediumGray !important',
        cursor: 'not-allowed',
    },
    '.unselected': {
        background: '$white !important',
        color: '$dark',
    },
})

export const PaginationControls = (props: any) => {
    const {
        isLastPage,
        isFirstPage,
        canRefine,
        currentRefinement,
        nbPages,
        pages,
        refine,
    } = usePagination(props)

    if (!canRefine) {
        return null
    }

    function handleClick(event: any, condition: boolean, refinement: number) {
        event.preventDefault()
        if (condition) {
            const element = window.document.getElementById(
                'alphabetical-filter'
            )
            element?.scrollIntoView({ behavior: 'smooth' })
            refine(refinement)
        }
    }

    return (
        <StyledPagination>
            <PaginationButton
                onClick={(event: any) => {
                    handleClick(event, !isFirstPage, 0)
                }}
                icon={'faAnglesLeft'}
                className={isFirstPage ? 'disable unselected' : 'unselected'}
            />
            <PaginationButton
                onClick={(event: any) => {
                    handleClick(event, !isFirstPage, currentRefinement - 1)
                }}
                icon={'faAngleLeft'}
                className={isFirstPage ? 'disable unselected' : 'unselected'}
            />
            {pages.map((page) => (
                <PaginationButton
                    key={page}
                    onClick={(event: any) => {
                        handleClick(event, true, page)
                    }}
                    label={page + 1}
                    className={page === currentRefinement ? 'selected' : ''}
                />
            ))}
            <PaginationButton
                onClick={(event: any) => {
                    handleClick(event, !isLastPage, currentRefinement + 1)
                }}
                icon={'faAngleRight'}
                className={isLastPage ? 'disable unselected' : 'unselected'}
            />
            <PaginationButton
                onClick={(event: any) => {
                    handleClick(event, !isLastPage, nbPages - 1)
                }}
                icon={'faAnglesRight'}
                className={isLastPage ? 'disable unselected' : 'unselected'}
            />
            <></>
        </StyledPagination>
    )
}
