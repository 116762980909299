import Image from 'next/legacy/image'
import { keyframes, styled } from '@/src/stitches.config'
import { useBrandSearchContext } from '@/src/modules/algolia/BrandSearchContext'
import { SELECTED_BRAND } from '@/src/modules/algolia/BrandSearchReducerActions'
import { Dispatch, useEffect, useRef, useState } from 'react'
import Link from 'next/link'
import { clickCallbackFn } from '@/src/modules/algolia/utilities'
import {
    BRAND_PG_PLUS,
    PG_PLUS_NAME,
    PG_PLUS_SLUG,
} from '@/prisma/schemaConstants'
import {
    PARTNER_BRAND_PAGE,
    PG_PLUS_BUILD_PAGE,
} from '@/src/modules/pg-plus/constants'

const HitItem = styled('div', {
    cursor: 'pointer',
    '.selected': {
        border: '3px solid $primary !important',
        br: 10,
    },
})

const BrandImageWrapper = styled('div', {})

const BrandImage = styled(Image, {
    br: '10px',
    border: '1px solid $lightest !important',
})
const BrandName = styled('p', {
    mt: '10px',
    mb: 0,
    fontWeight: 700,
    fontSize: '14px',
    lineHeight: '21px',
    whiteSpace: 'nowrap',
    display: 'inline-block',
    '&:not(:hover), &:not(:focus)': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '100%',
        animationName: 'none',
    },
    '&:hover, &:focus': {
        overflow: 'unset',
        width: 'fit-content !important',
        animationTimingFunction: 'linear',
        animationIterationCount: 1,
        animationFillMode: 'forwards',
        animationDelay: '.25s',
        animationDirection: 'normal',
    },
})

type THitProps = {
    isSearch: boolean
    slug: string
    children: JSX.Element | JSX.Element[]
    isPartnerFlow: boolean
}

const HitWrapper = ({ isSearch, slug, children, isPartnerFlow }: THitProps) => {
    if (isSearch) {
        let href: string

        switch (slug) {
            case 'visa':
                href = '/visa-gift-cards'
                break
            case `${PG_PLUS_SLUG}`:
                href = PG_PLUS_BUILD_PAGE
                break
            default:
                href = isPartnerFlow
                    ? `${PARTNER_BRAND_PAGE}/${slug}`
                    : `/brands/${slug}`
                break
        }

        return (
            <Link href={href} tabIndex={-1}>
                {children}
            </Link>
        )
    }
    return <>{children}</>
}

export interface IBrandSearchHit {
    hit: any
    sendEvent: Function
    isSearch: boolean
    dispatch?: Dispatch<any>
}

export const BrandSearchHit = ({
    hit,
    sendEvent,
    isSearch,
    dispatch,
}: IBrandSearchHit) => {
    const {
        brandState,
        brandDispatch,
        setNewBrandUuid,
        setNewBrandName,
        flow,
    } = useBrandSearchContext()
    const nameRef = useRef<any>(null)
    const [isActive, setIsActive] = useState<boolean>(false)
    const [hasLongName, setHasLongName] = useState<boolean>()
    const [widthDifference, setWidthDifference] = useState<number>(0)
    const [timing, setTiming] = useState<number>(0)
    const isSelected = Boolean(brandState?.name === hit.name)
    const isPgPlus: boolean = hit.name === BRAND_PG_PLUS
    const isPartnerFlow: boolean = flow === 'partner'

    useEffect(() => {
        if (nameRef.current) {
            const wrapperWidth = nameRef?.current?.offsetWidth
            const textWidth = nameRef?.current?.scrollWidth
            setHasLongName(wrapperWidth < textWidth)
            setWidthDifference(wrapperWidth - textWidth - 10)
            setTiming(widthDifference / -35)
        }
    }, [widthDifference])

    const scrollText = keyframes({
        '0%': {
            transform: 'translateX(0px)',
        },
        '5%': {
            transform: 'translateX(0px)',
        },
        '95%': { transform: `translateX(${widthDifference}px)` },
        '100%': { transform: `translateX(${widthDifference}px)` },
    })

    const clickHandler = () => {
        brandDispatch({
            type: SELECTED_BRAND,
            payload: hit,
        })

        //This "props.sendEvent" is for the search-insights thing.
        // https://www.algolia.com/doc/guides/building-search-ui/going-further/send-insights-events/react/#call-the-insights-function-to-send-events
        sendEvent('click', hit, 'Brand Clicked')

        // onClick event for hits (open modal or push to page)
        clickCallbackFn({
            brandState: hit,
            flow: flow,
            setNewBrandName: setNewBrandName,
            setNewBrandUuid: setNewBrandUuid,
            dispatch: dispatch,
        })
    }

    const logoUrl =
        hit.logo_url ||
        'https://res.cloudinary.com/gift-card-granny/image/upload/c_thumb,w_200,g_face/v1682361527/PGSite/product_images/pg_placeholder_yrxga5.png'

    // lets users tab into card styles to select
    const handleEnterPress = (e: any) => {
        if (e.code === 'Enter') {
            e.preventDefault()
            clickHandler()
        }
    }

    return (
        <HitWrapper
            isSearch={isSearch}
            isPartnerFlow={isPartnerFlow}
            slug={hit.slug}
        >
            <HitItem
                onClick={clickHandler}
                data-brand-uuid={hit.objectID}
                data-featured={hit.featured}
                data-priority-sort={hit.priority_sort}
            >
                <BrandImageWrapper>
                    <BrandImage
                        draggable={false}
                        title={hit.name}
                        src={logoUrl}
                        width={116}
                        height={82}
                        className={
                            isSelected ? 'selected hit-item' : 'hit-item'
                        }
                        data-testid={'brandImage'}
                        tabIndex={0}
                        onKeyPress={(e) => {
                            handleEnterPress(e)
                        }}
                        style={{ pointerEvents: 'none' }}
                    />
                </BrandImageWrapper>
                <BrandName
                    style={{
                        animationName:
                            hasLongName && isActive ? `${scrollText}` : 'none',
                        animationDuration: `${timing}s`,
                    }}
                    ref={nameRef}
                    onMouseEnter={() => {
                        hasLongName && setIsActive(true)
                    }}
                    onMouseLeave={() => {
                        hasLongName && setIsActive(false)
                    }}
                    onTouchStart={() => {
                        hasLongName && setIsActive(true)
                    }}
                    onTouchEnd={() => {
                        hasLongName && setIsActive(false)
                    }}
                >
                    {isPgPlus ? PG_PLUS_NAME : hit.name}
                </BrandName>
            </HitItem>
        </HitWrapper>
    )
}
