import { styled } from '@/src/stitches.config'
import { RefinementList } from 'react-instantsearch'

const StyledRefinementList = styled(RefinementList, {
    '> ul': {
        display: 'flex',
        flexWrap: 'wrap',
        gap: 7,
        p: 0,
        m: 0,
        '> li': {
            display: 'inline-block',
            my: 7,
            '> label': {
                backgroundColor: '$light',
                color: '$darkest',
                cursor: 'pointer',
                br: '$2',
                p: '0.5em 0.7em',
                '> input': { display: 'none' },
                '> span.ais-RefinementList-count': { display: 'none' },
            },
            '&.ais-RefinementList-item--selected': {
                backgroundColor: '$secondary',
                color: 'white',
                '> label': {
                    backgroundColor: '$secondary',
                    color: 'white',
                },
            },
        },
    },
})

export const RefinementListCategories = (props: any) => {
    return <StyledRefinementList attribute="categories" {...props} />
}
