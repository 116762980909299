import {
    SESSION_STORAGE_BRAND_OBJECT_ID,
    SESSION_STORAGE_BRAND_QUERY_ID,
} from '@/src/modules/algolia/constants'
import { addSelectItemAnalyticEvent } from '@/src/common/utilities/gtag'
import checkIfConsumerBrandExistsInBuilder from '@/src/common/queries/checkIfConsumerBrandExistsInBuilder'
import { TBrandFlows } from '@/src/modules/algolia/types'
import { DISPATCH_CHANGE_BRAND_MODAL } from '@/src/common/reducers/constants'
import { Dispatch } from 'react'

type TBrandCallback = {
    brandState: any
    flow: TBrandFlows
    setNewBrandName: Function
    setNewBrandUuid: Function
    dispatch?: Dispatch<any>
}

export const clickCallbackFn = ({
    brandState,
    flow,
    setNewBrandName,
    setNewBrandUuid,
    dispatch,
}: TBrandCallback) => {
    if (flow === 'pg_plus_redemption') {
        const { name = '', objectID = '' } = brandState

        setNewBrandName(name)
        setNewBrandUuid(objectID)

        // handles opening the modal without a confirm button
        dispatch && dispatch({ type: DISPATCH_CHANGE_BRAND_MODAL })
    }

    if (flow === 'search') {
        const {
            slug,
            name = '',
            categories = [],
            objectID = '',
            __queryID = '',
        } = brandState

        try {
            sessionStorage.setItem(SESSION_STORAGE_BRAND_QUERY_ID, __queryID)
            sessionStorage.setItem(SESSION_STORAGE_BRAND_OBJECT_ID, objectID)
        } catch {
        } finally {
            addSelectItemAnalyticEvent(name, categories)

            // show an error if the brand page doesn't exist
            checkIfConsumerBrandExistsInBuilder(slug)
                .then((r) => {
                    if (!slug || !r.props.has_brand) {
                        console.error('This brand is unavailable', {
                            slug: slug,
                            from_brands_page: true,
                        })
                    }
                })
                .catch((e) => {
                    console.error('Unable to determine if brand exists', {
                        slug: slug,
                        error: e,
                    })
                })
        }
    }
}

export function getFlowForAnalytics(flow: TBrandFlows) {
    switch (flow) {
        case 'pg_plus_redemption':
            return 'redemption'
        case 'search':
            return 'purchase'
        case 'partner':
            return 'purchase_via_partner'
        default:
            return ''
    }
}
