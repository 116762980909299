import {
    createContext,
    Dispatch,
    useContext,
    useEffect,
    useMemo,
    useReducer,
    useState,
} from 'react'
import {
    brandSearchReducer,
    StateObjectType,
} from '@/src/modules/algolia/BrandSearchReducer'
import { getFlowForAnalytics } from '@/src/modules/algolia/utilities'
import { TBrandFlows } from '@/src/modules/algolia/types'
import { viewList } from '@/src/common/utilities/gtag'
import useMemoizedState from '@/src/common/hooks/useMemoizedState'

type TBrandSearchContext = {
    brandState: StateObjectType
    brandDispatch: Dispatch<any>
    showMagnifyingGlass: boolean
    setShowMagnifyingGlass: Function
    newBrandName: string
    setNewBrandName: Function
    newBrandUuid: string
    setNewBrandUuid: Function
    existingGiftSlug: string
    setBrandHits: Function
    flow: TBrandFlows
}

export const BrandSearchContext = createContext<TBrandSearchContext | null>(
    null
)

export const useBrandSearchContext = () => {
    const context = useContext(BrandSearchContext)

    if (!context) {
        throw new Error('Please use BrandSearchContext in parent component')
    }

    return context
}

interface IBrandSearchProps {
    flow: TBrandFlows
    existingGiftSlug?: string
    children?: JSX.Element | JSX.Element[]
}

export const BrandSearchContextProvider = ({
    flow,
    children,
    existingGiftSlug = '',
}: IBrandSearchProps) => {
    const [brandHits, setBrandHits] = useMemoizedState([])
    const [brandState, brandDispatch] = useReducer(brandSearchReducer, {})
    const [newBrandName, setNewBrandName] = useState<string>('')
    const [newBrandUuid, setNewBrandUuid] = useState<string>('')
    const [showMagnifyingGlass, setShowMagnifyingGlass] =
        useState<boolean>(true)

    // only re-activate the brand list analytic event if the hits are available
    useEffect(() => {
        if (brandHits?.length) {
            viewList('Brand Search List', getFlowForAnalytics(flow), brandHits)
        }
    }, [brandHits, flow])

    const values = useMemo(
        () => ({
            brandState,
            showMagnifyingGlass,
            newBrandName,
            newBrandUuid,
            existingGiftSlug,
            flow,
            brandDispatch,
            setShowMagnifyingGlass,
            setNewBrandName,
            setNewBrandUuid,
            setBrandHits,
        }),
        [
            brandState,
            showMagnifyingGlass,
            newBrandName,
            newBrandUuid,
            existingGiftSlug,
            flow,
        ]
    )

    return (
        <BrandSearchContext.Provider value={values}>
            {children}
        </BrandSearchContext.Provider>
    )
}
