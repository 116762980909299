import { Hits } from 'react-instantsearch'
import { styled } from '@/src/stitches.config'
import { BrandSearchHit } from '@/src/modules/algolia/BrandSearchHit'

const StyledHits = styled(Hits, {
    p: 0,
    '> ol': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        m: 0,
        p: 0,
        '> li': {
            overflow: 'hidden',
            mx: 3,
            my: 8,
            p: 0,
        },
        '@sm': {
            '> li': {
                m: 12,
            },
        },
    },
    variants: {
        imageWidth: {
            default: {
                '> ol': {
                    '> li': {
                        width: 120,
                    },
                },
            },
            smaller: {
                '> ol': {
                    '> li': {
                        width: 100,
                    },
                },
            },
        },
        justifyHitsOnSmallUp: {
            center: {
                '> ol': {
                    '@sm': {
                        justifyContent: 'center',
                    },
                },
            },
            'space-around': {
                '> ol': {
                    '@sm': {
                        justifyContent: 'space-around',
                    },
                },
            },
            'flex-start': {
                '> ol': {
                    '@sm': {
                        justifyContent: 'flex-start',
                    },
                },
            },
        },
    },
    defaultVariants: {
        imageWidth: 'default',
        justifyHitsOnSmallUp: 'center',
    },
})

export const HitsList = (props: any) => {
    return (
        <StyledHits
            hitComponent={({ hit, sendEvent }) =>
                BrandSearchHit({
                    hit: hit,
                    sendEvent: sendEvent,
                    isSearch: !!props?.issearch,
                    dispatch: props?.dispatch,
                })
            }
            imageWidth={props?.imageWidth}
            justifyHitsOnSmallUp={props?.justifyHitsOnSmallUp}
            {...props}
        />
    )
}
