import { Menu } from 'react-instantsearch'
import { styled } from '@/src/stitches.config'

const StyledRefinementList = styled(Menu, {
    '> ul': {
        display: 'flex',
        flexFlow: 'row wrap',
        justifyContent: 'center',
        pl: 0,
        '> li': {
            display: 'inline-block',
            border: '1px solid transparent',
            backgroundColor: 'white',
            '&.ais-Menu-item--selected': {
                borderColor: '$primary',
            },
            '> a': {
                display: 'inline-block',
                cursor: 'pointer',
                width: '2.5em',
                textAlign: 'center',
                p: '0.5em',
                'span.ais-Menu-count': {
                    display: 'none',
                },
            },
        },
    },
    variants: {
        variant: {
            'solid-block': {},
            'separate-blocks': {
                '> ul': {
                    '> li': {
                        br: '$2',
                        m: 2,
                    },
                },
            },
            'smaller-solid-blocks': {
                '> ul': {
                    '> li': {
                        '> a': {
                            fontSize: '.85rem',
                            width: 'fit-content',
                        },
                    },
                },
            },
        },
    },
    defaultVariants: {
        variant: 'solid-block',
    },
})

export const MenuListAlphabetical = (props: any) => {
    return (
        <StyledRefinementList
            variant={props?.variant}
            attribute="name_first_letter"
            sortBy={['name']}
            limit={28}
            {...props}
        />
    )
}
