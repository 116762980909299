import { SearchBox } from 'react-instantsearch'
import { styled } from '@/src/stitches.config'
import { useBrandSearchContext } from '@/src/modules/algolia/BrandSearchContext'
import { ClipLoader } from 'react-spinners'

const StyledSearchBox = styled(SearchBox, {
    '> form': {
        display: 'flex',
        '> input.ais-SearchBox-input': {
            all: 'unset',
            flex: 1,
            borderColor: '$mediumGray',
            borderWidth: '2px',
            borderRadius: '$2',
            borderStyle: 'solid',
            padding: '$2',
            height: 35,
            fontSize: 15,
            lineHeight: 1,
            backgroundColor: 'white',
            textOverflow: 'ellipsis',
        },
        '> button.ais-SearchBox-submit': {
            all: 'unset',
            color: '$primary',
            cursor: 'pointer',
            flex: 0,
            borderColor: '$mediumGray',
            borderWidth: '2px 2px 2px 0',
            borderRadius: '0 $2 $2 0',
            borderStyle: 'solid',
            padding: '$2 $2',
            height: 35,
            fontSize: 15,
            lineHeight: 1,
            backgroundColor: 'white',
            display: 'none',
        },
        'input[type=search]::-webkit-search-cancel-button': { display: 'none' },
        '> button.ais-SearchBox-reset': {
            position: 'absolute',
            border: 'none',
            background: 'none',
            right: '1%',
            top: '30%',
            py: 5,
            '& svg': {
                height: '.85rem',
                width: '.85rem',
            },
        },
    },
})

const LoadingWrapper = styled('div', {
    position: 'absolute',
    top: 20,
    right: 10,
})

interface ISearch {
    props?: any
}

let timerId: any = undefined
const queryHook = (query: any, search: any) => {
    if (timerId) {
        clearTimeout(timerId)
    }
    timerId = setTimeout(() => search(query), 400)
}

function Loading() {
    return (
        <LoadingWrapper>
            <ClipLoader size={20} />
        </LoadingWrapper>
    )
}

export const SearchBoxInput = ({ props = null }: ISearch) => {
    const { setShowMagnifyingGlass } = useBrandSearchContext()
    return (
        <StyledSearchBox
            queryHook={queryHook}
            placeholder={'Search restaurants, retailers & more'}
            data-testid={'aisBrandsSearchBox'}
            onInput={(e: any) => setShowMagnifyingGlass(!e.target.value)}
            loadingIconComponent={Loading}
            {...props}
        />
    )
}
