import { styled } from '../../stitches.config'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons/faAngleLeft'
import { faAnglesLeft } from '@fortawesome/free-solid-svg-icons/faAnglesLeft'
import { faAnglesRight } from '@fortawesome/free-solid-svg-icons/faAnglesRight'
import { faAngleRight } from '@fortawesome/free-solid-svg-icons/faAngleRight'
import React from 'react'

const StyledButton = styled('button', {
    width: 43,
    height: 38,
    br: 6,
    fontSize: 15,
    fontWeight: 'bold',
    transition: 'background-color 150ms ease',
    color: '$dark',
    backgroundColor: 'white',
    border: '1px solid $primary',
    borderRadius: '$2',
    cursor: 'pointer',
    display: 'inline-block',
    '& svg': {
        fontSize: 12,
    },
    '&:not(:disabled):hover, &:not(:disabled):focus': {
        border: '1px solid $primary',
        backgroundColor: '$primaryLight',
        color: '$primary',
    },
    '&:disabled': {
        background: '$lightest',
        color: '$mediumGray',
        borderColor: '$mediumGray',
    },
})

interface IPaginationButton
    extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    icon?: 'faAnglesLeft' | 'faAngleLeft' | 'faAnglesRight' | 'faAngleRight'
    label?: string | number
}

const PaginationButton = ({ icon, label, ...props }: IPaginationButton) => {
    const iconName =
        icon === 'faAnglesLeft'
            ? faAnglesLeft
            : icon === 'faAngleLeft'
            ? faAngleLeft
            : icon === 'faAnglesRight'
            ? faAnglesRight
            : icon === 'faAngleRight'
            ? faAngleRight
            : null

    return (
        <StyledButton type={'button'} {...props}>
            {iconName && <FontAwesomeIcon icon={iconName} />}
            {label}
        </StyledButton>
    )
}

export default PaginationButton
