import { SELECTED_BRAND } from '@/src/modules/algolia/BrandSearchReducerActions'

export type StateObjectType = {
    name?: string
    objectID?: string
    __queryID?: string
    categories?: string[]
}
export type ActionType = {
    type: string
    payload: object
}
export const brandSearchReducer = (
    state: StateObjectType = {},
    action: ActionType
) => {
    switch (action.type) {
        case SELECTED_BRAND:
            state = action.payload
            return state
        default:
            console.warn(`No reducer handler for action type: "${action.type}"`)
            return state
    }
}
