export const DISPATCH_HIDE_MODAL = 'hide-modal'
export const DISPATCH_CHANGE_BRAND_MODAL = 'change-brand-modal'
export const DISPATCH_REDEEM_PLASTIC_MODAL = 'redeem-plastic-modal'
export const DISPATCH_CARD_LINK_MODAL = 'card-link-modal'
export const DISPATCH_REDEEM_EGIFT_MODAL = 'redeem-egift-modal'
export const DISPATCH_UNENROLL_MODAL = 'unenroll-modal'
export const DISPATCH_EDIT_SAVED_CARDS_MODAL = 'saved-cards'
export const DISPATCH_UNBRANDED_TO_LINK = 'unbranded-modal'
export const DISPATCH_UNBRANDED_TO_EGIFT = 'unbranded-to-egift-modal'
export const DISPATCH_STONLY_MODAL = 'stonly-modal'

export const DISPATCH_SET_STEP = 'set-step'
export const DISPATCH_SET_GIFT_STATE = 'set-gift-state'
