import { useClearRefinements } from 'react-instantsearch'
import { Button } from '@/src/common/components/marketing/buttons/Button'

export const CategoryMainClearRefinements = (props: any) => {
    const { refine, canRefine } = useClearRefinements(props)

    return Boolean(canRefine) ? (
        <Button
            size={'sm'}
            label={'Clear Filters'}
            textTransform={'uppercase'}
            variant={'primaryOutline'}
            css={{
                whiteSpace: 'nowrap',
                padding: '5px 20px',
                fontSize: '.85rem',
            }}
            onClick={refine}
        />
    ) : null
}
export default CategoryMainClearRefinements
