import { RefinementListCategories } from '@/src/modules/algolia/RefinementListCategories'
import CategoryMainClearRefinements from '@/src/modules/algolia/CategoryMainClearRefinements'
import { styled } from '@/src/stitches.config'

const FilterWrapper = styled('div', {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    width: '100%',
    gap: 10,
    mb: 20,
})
const Filters = styled('div', {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    gap: 10,
    variants: {
        variant: {
            none: {
                mt: 0,
            },
            'redemption-flow': {
                mt: 10,
                fontSize: '.9rem',
            },
        },
    },
})

interface IRefinementProps {
    variant?: 'redemption-flow' | 'none'
}

const BrandSearchRefinements = ({ variant = 'none' }: IRefinementProps) => {
    return (
        <FilterWrapper>
            <Filters variant={variant}>
                <RefinementListCategories />
            </Filters>
            <CategoryMainClearRefinements />
        </FilterWrapper>
    )
}

export default BrandSearchRefinements
