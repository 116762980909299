import { useLayoutEffect } from 'react'
import { useInstantSearch } from 'react-instantsearch-core'
import aa from 'search-insights'
import { createInsightsMiddleware } from 'instantsearch.js/es/middlewares'
import { useUser } from '@/src/modules/auth/context/AuthProvider'

export function InsightsMiddleware() {
    const { addMiddlewares } = useInstantSearch()
    const { user } = useUser()
    const userToken = user?.uid ?? 'guest'

    useLayoutEffect(() => {
        const middleware = createInsightsMiddleware({
            // @ts-ignore
            insightsClient: window?.aa ?? aa,
            onEvent: (event, aa) => {
                const { insightsMethod, payload } = event

                if (!payload.userToken) {
                    payload.userToken = userToken
                }

                if (insightsMethod && insightsMethod !== 'viewedObjectIDs') {
                    aa(insightsMethod, payload)
                }
            },
        })

        // createInsightsMiddleware returns an InternalMiddleware type
        // addMiddlewares requires a Middleware type
        // @ts-ignore
        return addMiddlewares(middleware)
    }, [addMiddlewares])

    return null
}
