import { BUILDER_IO_VERSION } from '@/src/common/constants'

export const checkIfConsumerBrandExistsInBuilder = async (slug: string) => {
    const APIKey = process.env.NEXT_PUBLIC_BUILDER_API_KEY
    const res = await fetch(
        `https://cdn.builder.io/api/${BUILDER_IO_VERSION}/content/consumer-brands?apiKey=${APIKey}&query.data.slug=${slug}&limit=1`
    )
    const json = await res.json()

    return {
        props: {
            has_brand: !!json?.results[0],
        },
    }
}

export default checkIfConsumerBrandExistsInBuilder
